@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    @apply p-0 m-0;
        font-family: Poppins, sans-serif;
}

html, body, #root {
    @apply flex
        w-full h-full flex-col items-center box-border relative bg-white;
}

.max-one-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.max-two-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.max-three-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.without-scrollbar {
    overflow: auto;
}

.without-scrollbar::-webkit-scrollbar {
    display: none;
}

.without-scrollbar {
    scrollbar-width: none;
}

.gradient {
    background-image: radial-gradient(circle at 50% 0, #ffe6cf, #fff 45%);
}

.notification-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.notification-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.notification-exit {
    opacity: 1;
    transform: translateY(0);
}

.notification-exit-active {
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

@keyframes loading-spinner-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    aspect-ratio: 1;
    animation: loading-spinner-rotation 1s infinite linear;
}

.product-image-1 {
    background-image: url("./media/product-image-1.png");
}

.product-image-2 {
    background-image: url("./media/product-image-2.png");
}

.product-image-3 {
    background-image: url("./media/product-image-3.png");
}

.radio-input {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #0035ff;
    border-radius: 50%;
    min-width: 12px;
    width: 12px;
    min-height: 12px;
    height: 12px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
}

.radio-input:checked {
    @apply shadow-[inset_0_0_0_2px_#ffffff];

    background: #0035ff;
    color: white;
}

.slider {
    -webkit-appearance: none;
    appearance: none;
    height: 4px;
    background: #e4e0e1;
    border-radius: 4px;
    outline: none;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #0035ff;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #0035ff;
    border-radius: 50%;
    cursor: pointer;
}

.slider::-ms-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    background: #0035ff;
    border-radius: 50%;
    cursor: pointer;
}

@media (min-width: 768px) {
    .slider::-webkit-slider-thumb {
        width: 14px;
        height: 14px;
    }

    .slider::-moz-range-thumb {
        width: 14px;
        height: 14px;
    }

    .slider::-ms-thumb {
        width: 14px;
        height: 14px;
    }
}
